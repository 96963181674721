import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MainLayout } from './layout/Main';
import { Dashboard } from './components/Dashboard';

function App() {
  return (
    <div>
      <MainLayout >
        <Dashboard />
      </MainLayout>
    </div>
  );
}

export default App;
