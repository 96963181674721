import { NavbarComponent } from "../components/Navbar"
import MenuIcon from '../assets/imgs/menu.png'
import CloseIcon from '../assets/imgs/close.png'
import './styles.css';
import { useState } from "react";

export const MainLayout = (props:any)=>{
    const [isNavbarOpen,setIsNavbarOpen] = useState(false);
    return (
        <div className='main-container'>
            <div className={`navbar-container__main ${isNavbarOpen && 'navbar-container-active'}`}>
            <NavbarComponent />
            </div>
            <div className="mobile-navbar-toggler" onClick={()=>setIsNavbarOpen(!isNavbarOpen)}>
                {
                    isNavbarOpen?
                    <img className="menu-icon-img" src={CloseIcon} alt="" />
                    :
                    <img className="menu-icon-img" src={MenuIcon} alt="" />
                }
                
            </div>
            {props.children}
        </div>
    )
}