import { Nav, Navbar, NavItem } from 'reactstrap';
import './styles.css';
import DashboardIcon from '../../assets/svgs/dashboard-icon.svg';
import ClaimRewardIcon from '../../assets/svgs/claim-reward-icon.svg';
import InsuranceDetailIcon from '../../assets/svgs/insurance-detail-icon.svg';
import SwapIcon from '../../assets/svgs/swap-icon.svg';
import DocsIcon from '../../assets/svgs/docs-icon.svg';

export const NavbarComponent = ()=>{
    return(
        <div className="navbar-container">
            <Navbar className=''>
                <Nav className='nav-item-container'>
                    <NavItem className='nav-item active'>
                        <img src={DashboardIcon} alt="" />
                        {' '}
                        Dashboard
                    </NavItem>
                    <NavItem className='nav-item'>
                    <img src={ClaimRewardIcon} alt="" />
                    {' '}
                        Claim Reward
                    </NavItem>
                    <NavItem className='nav-item'>
                    <img src={InsuranceDetailIcon} alt="" />
                        {' '}
                        Insurance Detail
                    </NavItem>
                    <br />
                    <br />
                    <NavItem className='nav-item'>
                    <img src={SwapIcon} alt="" />
                    {' '}
                        Swap
                    </NavItem>
                    <NavItem className='nav-item'>
                    <img src={DocsIcon} alt="" />
                        {' '}
                        Docs
                    </NavItem>
                </Nav>
            </Navbar>
        </div>
    )
}