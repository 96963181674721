import './styles.css';
import TitleLeft from '../../assets/svgs/title-left.svg';
import TitleRight from '../../assets/svgs/title-right.svg';
import { Card } from '../Card';

export const Dashboard = ()=>{
    return (
        <div className="dashboard-container">

            <div className="title__text">
                GREEN CRYPTO CAPITAL
            </div>
            <div className="section__heading">
                <span className='heading-container'>
                <img src={TitleLeft} alt="" />
                <span className='seaction__heading-text'>
                    DASHBOARD
                </span>
                <img src={TitleRight} alt="" />
                </span>
            </div>

            <div className='row'>
                <Card>
                    <div className='container-top-section'>
                        <div className='container-top-section__top'>
                            <div className='card-container-top-section__heading'>Rewards</div>
                            <div className='card-container-top-section__value'>0.000</div>
                        </div>
                        {/* <div className='container-top-section__top'>
                            <div className='card-container-top-section__heading'>Rewards</div>
                            <div className='card-container-top-section__value'>0.000</div>
                        </div> */}
                    </div>
                </Card>
                <Card>
                    <div className='container-top-section'>
                        <div className='container-top-section__top'>
                            <div className='card-container-top-section__heading'>Rewards</div>
                            <div className='card-container-top-section__value'>0.000</div>
                        </div>
                        {/* <div className='container-top-section__top'>
                            <div className='card-container-top-section__heading'>Rewards</div>
                            <div className='card-container-top-section__value'>0.000</div>
                        </div> */}
                    </div>
                </Card>
                <Card>
                    <div className='container-top-section'>
                        <div className='container-top-section__top'>
                            <div className='card-container-top-section__heading'>Rewards</div>
                            <div className='card-container-top-section__value'>0.000</div>
                        </div>
                        {/* <div className='container-top-section__top'>
                            <div className='card-container-top-section__heading'>Rewards</div>
                            <div className='card-container-top-section__value'>0.000</div>
                        </div> */}
                    </div>
                </Card>
                
            </div>
        </div>
    )
}